import React, { useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import Input from '../../Components/Input';
import Checkbox from '../../Components/Checkbox';
import Button from '../../Components/Button';
import { sendContactMail } from '../../Redux/Action/FrontendAction';
import LoadingModal from '../../Components/LoadingModal';
import Footer from './Components/Footer';
import Textarea from '../../Components/Textarea';
import StartpageHeader from './Components/StartpageHeader';
import { HeadlineBox } from './StyledComponents';

const image = require('../../Assets/Images/phone-call-contact.jpg');

/**
 * Contact()
 * @returns {*}
 * @constructor
 */
function Contact(props) {
  const { dispatchSendMail } = props;
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [agb, setAgb] = useState(false);
  const [send, setSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const sendMail = () => {
    setErrors({});

    if (agb && name && mail && phone && message) {
      setIsLoading(true);
      const data = {
        name, mail, phone, message,
      };
      dispatchSendMail(data).then(() => {
        setIsLoading(false);
        setSend(true);
      }).catch(() => {
        setIsLoading(false);
      });
    } else {
      const errorData = {
        name: (!name || name.length === 0),
        mail: (!mail || mail.length === 0),
        phone: (!phone || phone.length === 0),
        agb: (!agb),
        message: (!message || message.length === 0),
      };
      setErrors(errorData);
    }
  };

  const renderContent = () => {
    if (send) {
      return (
        <div className="row" style={{ marginTop: 50 }}>
          <div className="col-lg-12">
            <div style={{ textAlign: 'center' }}>
              <i
                className="fas fa-check-circle text-success"
                style={{ marginRight: 6, fontSize: 50, marginBottom: 10 }}
              />
              <div>
                Vielen Dank für Ihre Andfrage. Wir werden Sie in kürze persönlich kontaktieren!
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <>
        <Input
          label="Vor- & Nachname"
          onChange={(data) => setName(data)}
          value={name}
          required
          hasError={!!(errors.name)}
        />
        <Input
          label="Email Adresse"
          onChange={(data) => setMail(data)}
          value={mail}
          required
          hasError={!!(errors.mail)}
        />
        <Input
          label="Telefon"
          onChange={(data) => setPhone(data)}
          value={phone}
          required
          hasError={!!(errors.phone)}
        />

        <Textarea
          label="Nachricht"
          rows={8}
          onChange={(data) => setMessage(data)}
          value={message}
          required
          hasError={!!(errors.message)}
        />

        <div className="col-lg-12">
          <Checkbox onChange={(data) => setAgb(data)} value={agb} hasError={!!(errors.agb)}>
            Mit der Nutzung dieses Formulars erklären Sie sich mit der Speicherung und Verarbeitung Ihrer
            Daten durch diese Website einverstanden.
            <br />
            Weitere Informationen erhalten Sie in der Datenschutzerklärung
          </Checkbox>
        </div>

        <div className="col-lg-12 justify-content-center d-flex" style={{ marginTop: 30 }}>
          <Button onClick={() => sendMail()}>
            Abschicken
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="d-flex flex-grow-1 flex-column" style={{ paddingTop: 90 }}>
      <StartpageHeader image={image} height={400} withText={false} />

      <div className="container d-flex flex-column" style={{ paddingTop: 60, paddingBottom: 60 }}>
        <div className="row">
          <div className="col-lg-12" style={{ marginBottom: 40 }}>
            <HeadlineBox className="Red" style={{ marginBottom: 15 }}>
              Kontakt
            </HeadlineBox>
            <p>
              Wir sind gerne für Sie da und helfen bei allen Fragen oder Problemen rund um hey.kitchen!
            </p>
          </div>

          <div className="col-lg-6" style={{ marginBottom: 50 }}>
            {renderContent()}
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column align-content-center align-items-center" style={{ fontSize: 18 }}>
              <i
                style={{ marginTop: 30, marginBottom: 10, fontSize: 30 }}
                className="fas fa-id-card hk-text-primary"
              />
              <div><strong>hey.kitchen GmbH</strong></div>
              <div>Rennweg 60</div>
              <div>56626 Andernach</div>
              <div>RLP, Deutschland</div>

              <i
                style={{ marginTop: 50, marginBottom: 10, fontSize: 30 }}
                className="fas fa-clock hk-text-primary"
              />
              <div><strong>Öffnungszeiten</strong></div>
              <div>Montag - Freitag - 8:00 - 18:00</div>
              <div>Samstag & Sonntag - geschlossen</div>

              <i
                style={{ marginTop: 50, marginBottom: 10, fontSize: 30 }}
                className="fas fa-phone-square hk-text-primary"
              />
              <div><strong>Kontakt</strong></div>
              <div>kontakt@hey.kitchen</div>
              <div>+49 (2266) 478 9296</div>
              <div>https://www.hey.kitchen</div>
            </div>
          </div>
        </div>
      </div>

      <LoadingModal visible={isLoading} text="Ihre Anfrage wird versendet..." />
      <Footer type="Dark" />
    </div>
  );
}

Contact.propTypes = {
  dispatchSendMail: PropTypes.func.isRequired,
};

Contact.defaultProps = {

};
function mapDispatchToProps(dispatch) {
  return {
    dispatchSendMail: (data) => dispatch(sendContactMail(data)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(Contact);
